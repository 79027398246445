import { useEffect, useState } from "react";
import { AlertColor, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { CONFIG } from "../../services/config";
import ModalEditRevalidation from "./modalEditRevalidation";
import MyModal from "../../components/MyModal";
import Report from "../../components/report";
import QuickAlert from "../../components/form/quickAlert";
import BoxContent from "../../components/boxContent";
import DataGridApi from "./dataGridApiRevalidation";
import formatStatus from "../../utils/formatStatusName";
import useReportRevalidation from "../../hooks/ReportRevalidation";

export default function Revalidation() {
  const [dataUpdated, setDataUpdated] = useState(0);
  const [openModalEditCountry, setOpenModalEditCountry] = useState(false);
  const [revalidationToEdit, setRevalidationToEdit] = useState<any>();
  const [openModalReport, setOpenModalReport] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const { t } = useTranslation();

  const formatTranslatedStatus = (status: string) => {
    const match = status.match(/^(\d+)[ºª]?\s*(.+)$/);

    if (!match) return status;

    const numberPart = match[1];
    // const textPart = match[2].toLowerCase();
    return `${numberPart}º ${t("revalidation.list.service")}`;
  };

  const columns = [
    {
      field: "vin",
      headerName: t("revalidation.list.columnTitle"),
      width: 191,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "osNumber",
      headerName: t("revalidation.list.columnTitle2"),
      width: 100,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "revisionDate",
      headerName: t("revalidation.list.columnTitle3"),
      width: 112,
      headerClassName: "header-style",
      valueFormatter: (params: any) =>
        moment(params?.value).format("DD/MM/YYYY"),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "odometer",
      headerName: t("revalidation.list.columnTitle4"),
      width: 105,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: t("revalidation.list.columnTitle5"),
      width: 110,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }: any) {
        return <span>{formatStatus(row.status)}</span>;
      },
    },
    {
      field: "serviceLost",
      headerName: t("revalidation.list.columnTitle6"),
      width: 140,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }: any) {
        return <span>{formatTranslatedStatus(row.serviceLost)}</span>;
      },
    },
    {
      field: "dn",
      headerName: t("revalidation.list.columnTitle7"),
      width: 100,
      headerClassName: "header-style",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const refreshPage = () => {
    setDataUpdated(dataUpdated + 1);
  };

  const handleOnRowClick = (params: any) => {
    setOpenModalEditCountry(true);
    setRevalidationToEdit(params);
  };

  useEffect(() => {
    setMessageQuickAlert(t("revalidation.alert.sucess"));
    setTitleMessage(t("quickAlert.sucess"));
  }, []);

  const callbackRevalidationReport = (report: {
    status: string;
    fileUrl: string;
  }) => {
    setMessageQuickAlert(t("revalidation.alert.successReport"));
    const link = document.createElement("a");
    link.href = report.fileUrl;
    link.click();
    window.sessionStorage.removeItem("rp_report");
    setVisibleAlert(true);
    setOpenModalReport(false);
  };

  return (
    <BoxContent
      title={t("revalidation.list.pageTitle")}
      button
      titleButton={
        window.innerWidth > 900
          ? t("revalidation.list.textRelatoryButton")
          : t("revalidation.list.textRelatoryButton2")
      }
      handleClick={() => setOpenModalReport(true)}
      noGlobal
    >
      <Box
        sx={{
          marginBottom: 3,
        }}
      >
        <span style={{ fontSize: 15, fontWeight: 400 }}>
          {t("revalidation.list.pagetitle2")}
        </span>
      </Box>

      <Box
        sx={{
          marginBottom: 1,
        }}
      >
        <span style={{ fontSize: 12 }}>
          {t("revalidation.list.searchInputLabel")}
        </span>
      </Box>

      <DataGridApi
        onRowClick={handleOnRowClick}
        // mock={[
        //   {
        //     id: 8608799,
        //     vin: "9BWCH6CH5PP004756",
        //     osNumber: 30609,
        //     revisionDate: "2022-01-01",
        //     odometer: 15000,
        //     status: "PENDING",
        //     type: "FIRST_REVALIDATION",
        //     dn: 1443,
        //   },
        // ]}
        host={CONFIG.SOB_API}
        endPoint="revalidate-plan?"
        columnsData={columns}
        params1="&searchText="
        labelParams1={t("revalidation.list.searchInputText")}
        label2Params1={t("revalidation.list.searchInputDateLabel2")}
        CleanButtonStyle={{
          backgroundColor: "white",
          color: "#00B0F0",
          borderColor: "#00B0F0",
          width: 120,
        }}
        SearchButtonStyle={{
          width: 120,
        }}
        CleanButtonType="outlined"
        CleanButtonText={t("revalidation.list.cleanButtonText")}
        boxInputstyle={
          window.innerWidth > 900
            ? {
                display: "flex",
                flexDirection: "row",
                margin: "18px 0 0 0",
              }
            : undefined
        }
        gridInputStyle={{
          width: window.innerWidth > 900 ? "35.5%" : undefined,
        }}
        boxButtonMargin={window.innerWidth > 900 ? "20px" : "0"}
        dataUpdated={dataUpdated}
      />

      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
        hanhleClose={() => setVisibleAlert(false)}
      />

      {openModalEditCountry && (
        <ModalEditRevalidation
          onClose={() => setOpenModalEditCountry(false)}
          revalidationToEdit={revalidationToEdit}
          openSucessAlert={() => setVisibleAlert(true)}
          refreshPage={refreshPage}
          openErrorAlert={() => {
            setVisibleAlert(true);
            setSeverityAlert("error");
            setMessageQuickAlert(t("revalidation.alert.error"));
            setTitleMessage(t("quickAlert.error"));
          }}
        />
      )}
      {openModalReport && (
        <MyModal title={t("report.title")} modalChange={setOpenModalReport}>
          <Report
            callbackRevalidationReport={callbackRevalidationReport}
            modalStatus={openModalReport}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
