import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/system";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";
import ActionButtons from "../../MyModal/ActionButtons";
import "react-quill/dist/quill.snow.css";

import { LabelContainer, LabelForm } from "../Form/label";

type FormValues = {
  id: string;
  question: string;
  systems: number[];
  categoryId: number;
  answer: string;
  active: boolean;
  image: any;
};
interface IData {
  id: string;
  question: string;
  faqSystems: {
    system: ISystem;
  }[];
  category: ICategory;
  answer: string;
  active: boolean;
  image: any;
}

interface ISystem {
  id: number;
  name: string;
  active: boolean;
  countryCode: string;
  systemCode: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

interface ICategory {
  id: number;
  name: string;
}

interface EditQuestionProps {
  data: IData;
  watchDataChange: (message: string) => void;
  messageError: (message: string) => void;
}

export default function EditQuestion({
  data,
  watchDataChange,
  messageError,
}: EditQuestionProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  yup.setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });
  const [richText, setRichText] = useState("");
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [selectedSystems, setSelectedSystems] = useState<ISystem[]>([]);
  const [systems, setSystems] = useState<ISystem[]>([]);
  const quillRef = useRef<any>(null);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | string>("");
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);
  const editFormData = yup.object().shape({
    question: yup
      .string()
      .max(85)
      .required(t("faq.question.Required question"))
      .label(t("faq.question.inputs.Question")),
    systems: yup.array().required(t("faq.question.Required systems")),
    categoryId: yup.number().required(t("faq.question.Required category")),
    answer: yup.string().required(t("faq.question.Required answer")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(editFormData) });
  useEffect(() => {
    async function load() {
      const [respSystem, respCategories] = await Promise.all([
        axios.get(`${CONFIG.API_URL}faq/systems`, {
          headers: tokenHeaders.headers,
          data: null,
        }),
        axios.get(`${CONFIG.API_URL}faq/categories`, {
          headers: tokenHeaders.headers,
          data: null,
        }),
      ]);

      setSystems(respSystem.data);
      setCategories(respCategories.data.content);
    }
    load();
  }, []);

  useEffect(() => {
    if (data) {
      const initialSystems = data.faqSystems.map((item) => item.system);
      setValue("question", data.question);
      setSelectedSystems(initialSystems);
      setSelectedCategory(data.category.id);
      setValue("categoryId", data.category.id);
      setValue(
        "systems",
        initialSystems.map((item) => item.id),
      );
      setRichText(data.answer);
      setValue("answer", data.answer);
      setValue("active", data.active);
    }
  }, [data, systems]);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result?.toString().split(",")[1] || null);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    register("systems", {
      validate: (value) =>
        value.length > 0 || t("faq.question.Required systems"),
    });
    register("categoryId", {
      validate: (value) => !!value || t("faq.question.Required category"),
    });
    register("answer", {
      validate: (value) =>
        value.length > 0 || t("faq.question.Required answer"),
    });
  }, [register]);

  const deleteQuestion = async () => {
    try {
      await axios.delete(`${CONFIG.API_URL}faq/${data?.id}`, {
        headers: tokenHeaders.headers,
        data: null,
      });
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("faq.question.Question deleted"));
  };
  const handleCategoryChange = (event: any) => {
    const categoryId = event.target.value as number;
    setSelectedCategory(categoryId);
    setValue("categoryId", categoryId);
  };

  const handleSystemsChange = (event: any, value: ISystem[]) => {
    setSelectedSystems(value);
    setValue(
      "systems",
      value.map((system) => system.id),
    );
  };

  const handleChange2 = (
    content: string,
    delta: any,
    source: string,
    editor: any,
  ) => {
    setValue("answer", content);
    setRichText(content);
  };

  const selectedValues = React.useMemo(
    () => data.faqSystems.map((item) => item.system),
    [data],
  );

  const editSystem: SubmitHandler<FormValues> = async (values) => {
    try {
      const editor = quillRef?.current?.getEditor();
      const content = editor.getText().trim();
      console.log("content", content);

      if (!content) {
        throw new Error(t("faq.question.Required answer"));
      }
      const image = imageBase64
        ? {
            image: imageBase64,
          }
        : {};
      await axios.put(
        `${CONFIG.API_URL}faq/${data.id}`,
        {
          ...image,
          question: values.question,
          answer: values.answer,
          active: values.active,
          categoryId: values.categoryId,
          systems: values.systems,
        },
        tokenHeaders,
      );

      watchDataChange(t("faq.question.Question edited"));
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.question.question_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(editSystem)}
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <LabelContainer>
        <LabelForm label={t("faq.system.Name")}>
          <Autocomplete
            multiple
            options={systems}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // label={t('faq.question.label_select_systems')}
                placeholder={t("faq.question.placeholder_systems")}
                error={!!errors.systems}
                helperText={errors.systems?.message}
              />
            )}
            onChange={handleSystemsChange}
            value={selectedSystems}
            defaultValue={selectedValues}
            renderOption={(props, option, { selected }) => {
              /* eslint-disable react/prop-types */
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ChipProps={{
              style: {
                backgroundColor: theme.palette.primary.main, // Azul
                color: theme.palette.common.white,
              },
              deleteIcon: (
                <CancelIcon style={{ color: theme.palette.common.white }} />
              ), // Ícone de fechar customizado
            }}
          />
        </LabelForm>
        <LabelForm label="Selecione a categoria">
          <FormControl fullWidth disabled={!selectedSystems.length}>
            {/* <InputLabel
              id="category-select-label"
              style={{ background: '#fff' }}
            >
              {t('faq.question.inputs.Select category')}
            </InputLabel> */}
            <Select
              labelId="category-select-label"
              value={selectedCategory}
              onChange={handleCategoryChange}
              displayEmpty
              error={!!errors.categoryId}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {errors.categoryId && (
              <p
                style={{
                  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  margin: "3px 14px 0 14px",
                  fontWeight: 400,
                }}
              >
                {errors.categoryId.message}
              </p>
            )}
          </FormControl>
        </LabelForm>
        <LabelForm
          required
          label={t("faq.question.inputs.Question")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "85",
            },
          ]}
        >
          <TextField
            label={t("faq.question.inputs.Question")}
            style={{ width: "100%" }}
            error={!!errors.question}
            helperText={errors.question?.message}
            {...register("question")}
          />
        </LabelForm>
        <LabelForm label="Resposta">
          <div>
            <ReactQuill
              ref={quillRef}
              value={richText}
              onChange={handleChange2}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  [{ align: [] }],
                ],
              }}
              formats={[
                "font",
                "header",
                "bold",
                "italic",
                "underline",
                "list",
                "bullet",
                "link",
                "image",
                "align",
              ]}
              style={{
                fontFamily: "YourCustomFont, sans-serif",
                border: errors.answer ? "1px solid var(--red-100)" : "",
              }}
            />
            {errors.answer && (
              <p
                style={{
                  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  margin: "3px 14px 0 14px",
                  fontWeight: 400,
                }}
              >
                {errors.answer.message}
              </p>
            )}
          </div>
        </LabelForm>
        <LabelForm
          label="Imagem"
          requires={[
            {
              msg: t("faq.requires.recommended_dimension"),
              value: "240 pixels de largura",
            },
            {
              msg: t("faq.requires.image_type"),
              value: "JPEG",
            },
            {
              msg: t("faq.requires.maximum_size"),
              value: "5 MB",
            },
          ]}
        >
          <Grid container spacing={2} columns={{ xs: 6, sm: 8, md: 12 }}>
            <Grid item xs={6}>
              {/* <span>{t("faq.category.UploadImage")}</span> */}

              <input
                disabled={!selectedSystems.length}
                accept=".png, .jpg, .jpeg"
                type="file"
                {...register("image")}
                onChange={convertImageToBase64}
              />
            </Grid>
            <Grid item xs={6} bgcolor="#fff">
              <Box display="flex" alignItems="center" bgcolor="#fff">
                <p>Imagem atual:</p>
                <Box display="flex" alignItems="center" ml="16px">
                  {imageBase64 ? (
                    <img
                      src={`data:image/png;base64,${imageBase64}`}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      alt="main"
                    />
                  ) : data.image ? (
                    <img
                      src={`${data.image}`}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      alt="main"
                    />
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {errors.image && (
            <Box
              sx={{
                color: "var(--red-100)",
                marginBottom: 3,
              }}
            >
              <span>{String(errors?.image?.message)}</span>
            </Box>
          )}
        </LabelForm>
        <LabelForm label={t("faq.system.Status")}>
          <Grid item xs={6}>
            <FormGroup sx={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Switch
                    id="editActive"
                    {...register("active")}
                    defaultChecked={data.active}
                  />
                }
                label={t("faq.system.Status")}
              />
            </FormGroup>
          </Grid>
        </LabelForm>
      </LabelContainer>

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteQuestion}
        disableSave={!selectedSystems.length}
      />
    </Box>
  );
}
