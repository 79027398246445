import {
  Box,
  Grid,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { IPhoneX } from "react-device-frames";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { IVehicle } from "../../interfaces/IVehicle";

export default function AddAndEditSendPush({
  watchDataChange,
  messageError,
  data,
}: any) {
  const { t } = useTranslation();
  const {
    user,
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [id, setId] = useState<Number | null>(null);
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [vehicleVersions, setVehicleVersions] = useState<any[]>([]);
  const [colors, setColors] = useState<any[]>([]);
  const [sendImmediate, setSendImmediate] = useState<boolean>(false);
  const [date, setDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const [sendType, setSendType] = useState<string | null>(null);
  const [receiverType, setReceiverType] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [emails, setEmails] = useState<string[]>([]);
  const [year, setYear] = useState<Number | null>(null);
  const [modelId, setModelId] = useState<string | null>(null);
  const [versionId, setVersionId] = useState<string | null>(null);
  const [colorId, setColorId] = useState<string | null>(null);
  const [pr, setPr] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [text, setText] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const [linkError, setLinkError] = useState<string | null>(null);
  const [linkIsError, setLinkIsError] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [validCancel, setValidCancel] = useState<boolean>(true);
  const [validSave, setValidSave] = useState<boolean>(true);
  const [banner, setBanner] = useState<string | null>(null);
  const [extension, setExtension] = useState<string | null>(null);
  const [nameBanner, setNameBanner] = useState<string | null>(null);
  const [preview, setPreview] = useState<boolean>(true);

  useEffect(() => {
    if (data !== undefined) {
      if (
        data.sendImmediate === true ||
        data.statusId === 4 ||
        data.statusId === 1
      ) {
        setValidCancel(true);
      } else {
        setValidCancel(false);
      }

      if (data.statusId === 4 || data.statusId === 1) {
        setValidSave(true);
      } else {
        setValidSave(false);
      }

      const dates = data.sendDate !== null ? data.sendDate.split(" ") : null;
      if (data.sendType === "vehicle") {
        axios
          .get(
            `${CONFIG.API_URL}vehicles?countryCode=${countryCode}&`,
            tokenHeaders,
          )
          .then((response) => {
            setVehicles(response.data);
          });
        setVehicleVersions([]);
        setColors([]);
        axios
          .get(`${CONFIG.API_URL}vehicles/${data.modelId}`, tokenHeaders)
          .then((response) => {
            setVehicleVersions(response.data.versions);
            response.data.versions.map((value: any) => {
              if (value.id === data.versionId) {
                setColors(value.version);
              }
              return value;
            });
          });
      }
      setId(data.id);
      setDate(dates !== null ? dates[0] : null);
      setTime(dates !== null ? dates[1] : null);
      setSendType(data.sendType);
      setReceiverType(data.receiverType);
      setEmail(data.emails.length === 1 ? data.emails[0] : null);
      setEmails(data.emails);
      setYear(data.year);
      setModelId(data.modelId);
      setVersionId(data.versionId);
      setColorId(data.colorId);
      setPr(data.pr);
      setTitle(data.title);
      setText(data.text);
      setLink(data.link);
      setSendImmediate(data.sendImmediate);
      setBanner(data.mediaId !== null ? data.mediaId : null);
      setNameBanner(data.mediaId !== null ? data.mediaId.split("/")[6] : null);
    } else {
      setSendType("vehicle");
      setReceiverType("one");
      setValidSave(false);
    }
  }, []);

  const addAndEditSendPush = async () => {
    let emailsAux: string[] = [];
    if (email !== null) {
      emailsAux.push(email);
    }
    if (emails.length > 0) {
      emailsAux = emails;
    }
    if (valid === true) {
      try {
        axios.post(
          `${CONFIG.API_URL}sendPush`,
          {
            id,
            userCmsId: user.userId,
            sendDate: date !== "" && time ? `${date} ${time}` : null,
            sendType: Number(modelId) === 99999 ? "all" : sendType,
            receiverType,
            title,
            text,
            emails: emailsAux,
            pr,
            link,
            colorId: Number(colorId) === 99999 ? null : colorId,
            versionId: Number(versionId) === 99999 ? null : versionId,
            modelId: Number(modelId) === 99999 ? null : modelId,
            year,
            sendImmediate,
            banner,
            extension,
          },
          tokenHeaders,
        );
      } catch (error: any) {
        return messageError(error.response.data.message);
      }
    }
    watchDataChange(t("sendpush.sendPushAdded"));
  };

  const cancelSendPush = async () => {
    try {
      await axios.put(
        `${CONFIG.API_URL}sendPush/cancel/${data.id}`,
        {},
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("sendpush.sendPushDeleted"));
  };

  const getVehicles = async () => {
    await axios
      .get(
        `${CONFIG.API_URL}vehicles?countryCode=${countryCode}&`,
        tokenHeaders,
      )
      .then((response) => {
        response.data.push({ id: 99999, name: "Todos" });
        setVehicles(response.data);
      });
  };

  const getVehicleVersion = async (model: any) => {
    setVehicleVersions([]);
    setVersionId(null);
    setColors([]);
    setColorId(null);
    if (model !== 99999) {
      await axios
        .get(`${CONFIG.API_URL}vehicles/${model}`, tokenHeaders)
        .then((response) => {
          response.data.versions.push({ id: 99999, year, name: "Todos" });
          setVehicleVersions(response.data.versions);
        });
    } else {
      const response = [];
      response.push({ id: 99999, year, name: "Todos" });
      setVehicleVersions(response);
    }
  };

  const getColor = (versionId: any) => {
    if (versionId !== 99999) {
      vehicleVersions.map((value) => {
        if (value.id === versionId) {
          value.version.push({ colorId: 99999, colorName: "Todos" });
          setColors(value.version);
        }
        return value;
      });
    } else {
      const response = [];
      response.push({ colorId: 99999, colorName: "Todos" });
      setColors(response);
    }
  };

  const handleFile = async (e: any) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 2,
    });
    const json: any[] = [];
    jsonData.map((email: any) => {
      json.push(email["Email do cliente"]);
      return email;
    });
    setEmails(json);
    if (sendType === "vehicle") {
      if (
        (sendImmediate === true ||
          (date !== null && date !== "" && time !== null && time !== "")) &&
        modelId !== null &&
        modelId !== "" &&
        title !== null &&
        title !== "" &&
        text !== null &&
        text !== ""
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else if (sendType === "receiver") {
      if (
        (sendImmediate === true ||
          (date !== null && date !== "" && time !== null && time !== "")) &&
        ((receiverType === "lot" && json.length > 0) ||
          (receiverType === "one" && email !== "" && email !== null)) &&
        title !== null &&
        title !== "" &&
        text !== null &&
        text !== ""
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else if (sendType === "all") {
      if (
        (sendImmediate === true ||
          (date !== null && date !== "" && time !== null && time !== "")) &&
        title !== null &&
        title !== "" &&
        text !== null &&
        text !== ""
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  };

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    setExtension(`.${file.name.split(".").pop()}`);
    setNameBanner(file.name);
    const reader = new FileReader();
    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");
      setBanner(`data:image/png;base64,${image[1]}`);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box>
      <Grid container sx={{ marginBottom: "40px", fontSize: "12px" }}>
        {t("sendpush.mandatory")}
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <InputLabel>{t("sendpush.sendDate")}</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            disabled={sendImmediate}
            size="small"
            type="date"
            label={t("sendpush.date")}
            value={date ? date : ""}
            onChange={(event: any) => {
              setDate(event.target.value);
              if (sendType === "vehicle") {
                if (
                  (sendImmediate === true ||
                    (event.target.value !== null &&
                      event.target.value !== "" &&
                      time !== null &&
                      time !== "")) &&
                  modelId !== null &&
                  modelId !== "" &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "receiver") {
                if (
                  (sendImmediate === true ||
                    (event.target.value !== null &&
                      event.target.value !== "" &&
                      time !== null &&
                      time !== "")) &&
                  ((receiverType === "lot" && emails.length > 0) ||
                    receiverType === "one" ||
                    email !== "") &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "all") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            disabled={sendImmediate}
            size="small"
            type="time"
            label={t("sendpush.hour")}
            value={time ? time : ""}
            onChange={(event: any) => {
              setTime(event.target.value);
              if (sendType === "vehicle") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      event.target.value !== null &&
                      event.target.value !== "")) &&
                  modelId !== null &&
                  modelId !== "" &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "receiver") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      event.target.value !== null &&
                      event.target.value !== "")) &&
                  ((receiverType === "lot" && emails.length > 0) ||
                    receiverType === "one" ||
                    email !== "") &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "all") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  title !== null &&
                  title !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendImmediate}
                  onChange={(event) => {
                    setSendImmediate(event.target.checked);
                    if (sendType === "vehicle") {
                      if (
                        (event.target.checked === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        modelId !== null &&
                        modelId !== "" &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (sendType === "receiver") {
                      if (
                        (event.target.checked === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        ((receiverType === "lot" && emails.length > 0) ||
                          (receiverType === "one" &&
                            email !== "" &&
                            email !== null)) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (sendType === "all") {
                      if (
                        (event.target.checked === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    }
                  }}
                />
              }
              label={t("sendpush.sendImmediate")}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
      <Grid container>
        <FormControl sx={{ flexDirection: "row" }}>
          <FormLabel sx={{ alignSelf: "center", marginRight: "8px" }}>
            {t("sendpush.selectFormatPush")}
          </FormLabel>
          <RadioGroup value={sendType ? sendType : "vehicle"} row>
            <FormControlLabel
              value="vehicle"
              control={
                <Radio
                  onClick={(e: any) => {
                    setSendType(e.target.value);
                    setEmail(null);
                    setEmails([]);
                    setYear(null);
                    setModelId(null);
                    setVersionId(null);
                    setColorId(null);
                    setPr(null);
                    setValid(false);
                    if (e.target.value === "vehicle") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        modelId !== null &&
                        modelId !== "" &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "receiver") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        ((receiverType === "lot" && emails.length > 0) ||
                          (receiverType === "one" &&
                            email !== "" &&
                            email !== null)) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "all") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    }
                  }}
                />
              }
              label={t("sendpush.modelVehicle")}
            />
            <FormControlLabel
              value="receiver"
              control={
                <Radio
                  onClick={(e: any) => {
                    setYear(null);
                    setModelId(null);
                    setVersionId(null);
                    setColorId(null);
                    setPr(null);
                    setValid(false);
                    setEmail(null);
                    setEmails([]);
                    setSendType(e.target.value);
                    if (e.target.value === "vehicle") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        modelId !== null &&
                        modelId !== "" &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "receiver") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        ((receiverType === "lot" && emails.length > 0) ||
                          (receiverType === "one" &&
                            email !== "" &&
                            email !== null)) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "all") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    }
                  }}
                />
              }
              label={t("sendpush.receiver")}
            />
            <FormControlLabel
              value="all"
              control={
                <Radio
                  onClick={(e: any) => {
                    setYear(null);
                    setModelId(null);
                    setVersionId(null);
                    setColorId(null);
                    setPr(null);
                    setValid(false);
                    setEmail(null);
                    setEmails([]);
                    setSendType(e.target.value);
                    if (e.target.value === "vehicle") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        modelId !== null &&
                        modelId !== "" &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "receiver") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        ((receiverType === "lot" && emails.length > 0) ||
                          (receiverType === "one" &&
                            email !== "" &&
                            email !== null)) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else if (e.target.value === "all") {
                      if (
                        (sendImmediate === true ||
                          (date !== null &&
                            date !== "" &&
                            time !== null &&
                            time !== "")) &&
                        title !== null &&
                        title !== "" &&
                        text !== null &&
                        text !== ""
                      ) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    }
                  }}
                />
              }
              label={t("sendpush.all")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
      <Grid hidden={sendType !== "vehicle"}>
        <Grid container spacing={2}>
          <Grid item>
            <InputLabel>{t("sendpush.model")}</InputLabel>
          </Grid>
          <Grid item>
            <TextField
              required
              label={t("sendpush.year")}
              type="number"
              size="small"
              inputProps={{
                maxLength: 4,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event: any) => {
                setYear(Number(event.target.value));
                if (event.target.value.length === 4) {
                  getVehicles();
                }
              }}
              value={year ? year : ""}
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: 224 }} size="small">
              <InputLabel sx={{ alignSelf: "center" }}>
                {t("sendpush.modelSelect")}
              </InputLabel>
              <Select
                required
                disabled={year === undefined || year === null ? true : false}
                label={t("sendpush.modelSelect")}
                onChange={(event) => {
                  setModelId(event.target.value);
                  getVehicleVersion(event.target.value);
                  if (sendType === "vehicle") {
                    if (
                      (sendImmediate === true ||
                        (date !== null &&
                          date !== "" &&
                          time !== null &&
                          time !== "")) &&
                      event.target.value !== null &&
                      event.target.value !== "" &&
                      title !== null &&
                      title !== "" &&
                      text !== null &&
                      text !== ""
                    ) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  } else if (sendType === "receiver") {
                    if (
                      (sendImmediate === true ||
                        (date !== null &&
                          date !== "" &&
                          time !== null &&
                          time !== "")) &&
                      ((receiverType === "lot" && emails.length > 0) ||
                        (receiverType === "one" &&
                          email !== "" &&
                          email !== null)) &&
                      title !== null &&
                      title !== "" &&
                      text !== null &&
                      text !== ""
                    ) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  } else if (sendType === "all") {
                    if (
                      (sendImmediate === true ||
                        (date !== null &&
                          date !== "" &&
                          time !== null &&
                          time !== "")) &&
                      title !== null &&
                      title !== "" &&
                      text !== null &&
                      text !== ""
                    ) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }
                }}
                value={modelId ? modelId : ""}
              >
                {vehicles.map((vehicle: IVehicle, index: number) => {
                  return (
                    <MenuItem key={index} value={vehicle.id}>
                      {vehicle.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: 224 }} size="small">
              <InputLabel>{t("sendpush.version")}</InputLabel>
              <Select
                disabled={vehicleVersions.length === 0 ? true : false}
                label={t("sendpush.version")}
                size="small"
                onChange={(event) => {
                  setVersionId(event.target.value);
                  getColor(event.target.value);
                }}
                value={versionId ? versionId : ""}
              >
                {vehicleVersions.map((version: any, index: number) => {
                  return year === version.year ? (
                    <MenuItem key={index} value={version.id}>
                      {version.name}
                    </MenuItem>
                  ) : null;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: 224 }} size="small">
              <InputLabel>{t("sendpush.cor")}</InputLabel>
              <Select
                disabled={colors.length === 0 ? true : false}
                label={t("sendpush.cor")}
                size="small"
                onChange={(event) => setColorId(event.target.value)}
                value={colorId ? colorId : ""}
              >
                {colors.map((color: any, index: number) => {
                  return (
                    <MenuItem key={index} value={color.colorId}>
                      {color.colorName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ alignSelf: "center" }}>
            <TextField
              size="small"
              id="outlined-number"
              label={t("sendpush.pr")}
              type="text"
              onChange={(event: any) => setPr(event.target.value)}
              value={pr ? pr : ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid hidden={sendType !== "receiver"}>
        <FormControl sx={{ flexDirection: "row" }}>
          <FormLabel sx={{ alignSelf: "center", marginRight: "8px" }}>
            {t("sendpush.receiver")}
          </FormLabel>
          <RadioGroup value={receiverType ? receiverType : "one"} row>
            <FormControlLabel
              value="one"
              control={
                <Radio
                  onClick={(e: any) => {
                    setReceiverType(e.target.value);
                    setEmail("");
                    setEmails([]);
                    setValid(false);
                  }}
                />
              }
              label={t("sendpush.sendReceiver")}
            />
            <FormControlLabel
              value="lot"
              control={
                <Radio
                  onClick={(e: any) => {
                    setReceiverType(e.target.value);
                    setEmail("");
                    setEmails([]);
                    setValid(false);
                  }}
                />
              }
              label={t("sendpush.sendLot")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid hidden={sendType !== "receiver"}>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <Grid hidden={receiverType !== "one"}>
            <TextField
              required
              fullWidth
              size="small"
              label={t("sendpush.email")}
              onChange={(event: any) => {
                setEmail(event.target.value);
                if (sendType === "vehicle") {
                  if (
                    (sendImmediate === true ||
                      (date !== null &&
                        date !== "" &&
                        time !== null &&
                        time !== "")) &&
                    modelId !== null &&
                    modelId !== "" &&
                    title !== null &&
                    title !== "" &&
                    text !== null &&
                    text !== ""
                  ) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                } else if (sendType === "receiver") {
                  if (
                    (sendImmediate === true ||
                      (date !== null &&
                        date !== "" &&
                        time !== null &&
                        time !== "")) &&
                    ((receiverType === "lot" && emails.length > 0) ||
                      (receiverType === "one" &&
                        event.target.value !== "" &&
                        event.target.value !== null)) &&
                    title !== null &&
                    title !== "" &&
                    text !== null &&
                    text !== ""
                  ) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                } else if (sendType === "all") {
                  if (
                    (sendImmediate === true ||
                      (date !== null &&
                        date !== "" &&
                        time !== null &&
                        time !== "")) &&
                    title !== null &&
                    title !== "" &&
                    text !== null &&
                    text !== ""
                  ) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }
              }}
              value={email ? email : ""}
            />
          </Grid>
          <Grid hidden={receiverType !== "lot"}>
            {emails.length > 0 ? (
              <InputLabel>{t("sendpush.sendFile")}</InputLabel>
            ) : null}
            <Button variant="contained">
              <label htmlFor="xlsxFile">
                <input
                  accept=".xlsx"
                  className="xlsxFileClass"
                  type="file"
                  id="xlsxFile"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    handleFile(event);
                  }}
                />
                {t("sendpush.importFile")}
              </label>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
      <Grid container spacing={2}>
        <Grid item sx={{ marginTop: "24px" }}>
          <InputLabel>{t("sendpush.pushBanner")}</InputLabel>
        </Grid>
        <Grid item sx={{ marginTop: "24px", marginRight: "80px" }}>
          <Grid container sx={{ flexDirection: "column" }}>
            <Grid item>
              <Button
                variant="text"
                component="label"
                sx={{
                  width: 176,
                  height: 248,
                  cursor: "pointer",
                  display: "flex",
                  placeItems: "center",
                  justifyContent: "center",
                  border: banner === null ? "1px solid  var(--gray-200)" : "",
                  color: "var(--gray-900)",
                }}
              >
                <input
                  style={{ textAlign: "center" }}
                  hidden
                  accept=".png, .jpg, .jpeg"
                  className="imageFile"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                  onChange={(event) => {
                    convertImageToBase64(event);
                  }}
                />
                {banner === null ? (
                  <Grid sx={{ textAlign: "center" }}>
                    {t("sendpush.formatBanner")}
                  </Grid>
                ) : null}
                {banner === null ? null : (
                  <img
                    id="imageChosen"
                    alt={t("sendpush.addImage")}
                    style={{
                      width: 174,
                    }}
                    src={banner != null ? banner : ""}
                  />
                )}
              </Button>
            </Grid>
            <Grid
              container
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item>
                <InputLabel sx={{ fontSize: "10px" }}>{nameBanner}</InputLabel>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setBanner(null);
                    setExtension(null);
                    setNameBanner(null);
                    setPreview(!preview);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid hidden={preview}>
          <Grid item sx={{ float: "right" }}>
            <IconButton onClick={() => setPreview(!preview)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item sx={{ width: "250px", padding: 0 }}>
            <IPhoneX screenshot={banner != null ? banner : ""} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item hidden={banner != null ? false : true}>
          <Button
            variant="text"
            sx={{
              color: "#00AFF2",
              textTransform: "none",
              padding: 0,
              marginTop: "24px",
            }}
            onClick={() => setPreview(!preview)}
          >
            {t("sendpush.preview")}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <TextField
            required
            fullWidth
            size="small"
            label={t("sendpush.title")}
            onChange={(event: any) => {
              setTitle(event.target.value);
              if (sendType === "vehicle") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  modelId !== null &&
                  modelId !== "" &&
                  event.target.value !== null &&
                  event.target.value !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "receiver") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  ((receiverType === "lot" && emails.length > 0) ||
                    (receiverType === "one" &&
                      email !== "" &&
                      email !== null)) &&
                  event.target.value !== null &&
                  event.target.value !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "all") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  event.target.value !== null &&
                  event.target.value !== "" &&
                  text !== null &&
                  text !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }
            }}
            inputProps={{
              maxLength: 60,
            }}
            value={title ? title : ""}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <TextField
            required
            multiline
            rows={4}
            fullWidth
            label={t("sendpush.text")}
            onChange={(event: any) => {
              setText(event.target.value);
              if (sendType === "vehicle") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  modelId !== null &&
                  modelId !== "" &&
                  title !== null &&
                  title !== "" &&
                  event.target.value !== null &&
                  event.target.value !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "receiver") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  ((receiverType === "lot" && emails.length > 0) ||
                    (receiverType === "one" &&
                      email !== "" &&
                      email !== null)) &&
                  title !== null &&
                  title !== "" &&
                  event.target.value !== null &&
                  event.target.value !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              } else if (sendType === "all") {
                if (
                  (sendImmediate === true ||
                    (date !== null &&
                      date !== "" &&
                      time !== null &&
                      time !== "")) &&
                  title !== null &&
                  title !== "" &&
                  event.target.value !== null &&
                  event.target.value !== ""
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }
            }}
            inputProps={{
              maxLength: 100,
            }}
            value={text ? text : ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <TextField
            error={linkIsError}
            helperText={linkError}
            fullWidth
            size="small"
            label={t("sendpush.link")}
            onChange={(event: any) => {
              setLink(event.target.value);
              if (event.target.value !== "") {
                const url =
                  String(event.target.value).includes("http") ||
                  String(event.target.value).includes("https");
                if (url === false) {
                  setLinkError("A URL tem que conter http ou https");
                  setLinkIsError(true);
                } else {
                  setLinkError("");
                  setLinkIsError(false);
                }
              } else {
                setLinkError("");
                setLinkIsError(false);
              }
            }}
            value={link ? link : ""}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "32px",
        }}
      >
        <Grid item hidden={validSave}>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#00AFF2",
              boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
              color: "#ffffff",
              opacity: valid ? 1 : 0.5,
            }}
            disabled={!valid}
            onClick={addAndEditSendPush}
          >
            {t("button.Save")}
          </Button>
        </Grid>
        <Grid item hidden={validCancel}>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#d32f2f",
              boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
              color: "#ffffff",
            }}
            onClick={cancelSendPush}
          >
            {t("button.Cancel")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
