import { useContext } from "react";
import { Box, Grid, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";

import { FormInputText } from "../../../components/form/FormInputText";
import ActionButtons from "../../../components/MyModal/ActionButtons";
import MyModal from "../../../components/MyModal";

import { COMPLETED, CANCELLED } from "../../../constants";
import formatStatus from "../../../utils/formatStatusName";

type Props = {
  onClose: Function;
  openSucessAlert: Function;
  openErrorAlert: Function;
  refreshPage: Function;
  revalidationToEdit: {
    id: Number;
    vin: String;
    serviceLost: String;
    odometer: any;
    osNumber: Number;
    revisionDate: String;
    status: String;
    dn: String;
  };
};

export interface FormValues {
  vin: String;
  serviceLost: String;
  odometer: Number;
  osNumber: Number;
  revisionDate: String;
  status: String;
  dn: String;
}

export default function ModalEditRevalidation({
  onClose,
  openSucessAlert,
  openErrorAlert,
  refreshPage,
  revalidationToEdit,
}: Props) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);
  const status = [COMPLETED, CANCELLED];

  const formData = yup.object().shape({
    vin: yup.string().label(t("revalidation.edit.chassi")),
    odometer: yup.string().required(t("revalidation.edit.odometerError")),
    osNumber: yup.string().required(t("revalidation.edit.osNumberError")),
    revisionDate: yup.string().required(t("revalidation.edit.revisionDate")),
    serviceLost: yup.string().label(t("revalidation.edit.lostService")),
    status: yup.string().label(t("revalidation.edit.status")),
    dn: yup.string().required(t("revalidation.edit.dnCode")),
  });

  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(formData),
  });

  const { errors } = formState;

  const HorizontalLine = (
    <Box
      sx={{
        borderTop: "1px solid var(--gray-100)",
        marginBottom: 3,
        marginTop: 2,
      }}
    />
  );

  const editRevalidationService: SubmitHandler<FormValues> = async (values) => {
    try {
      await axios.put(
        `${CONFIG.SOB_API}revalidate-plan`,
        {
          id: revalidationToEdit.id,
          odometer: values.odometer,
          osNumber: values.osNumber,
          revisionDate: values.revisionDate,
          status: values.status,
          dn: values.dn,
        },
        tokenHeaders,
      );
      onClose();
      refreshPage();
      openSucessAlert();
    } catch (error: any) {
      onClose();
      openErrorAlert();
    }
  };

  return (
    <MyModal
      title={t("revalidation.edit.titleModal")}
      modalChange={onClose}
      component="form"
      onSubmit={handleSubmit(editRevalidationService)}
    >
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <span>{t("revalidation.edit.vehicle")}</span>
        </Grid>
        <Grid item md={5} xs={12}>
          <FormInputText
            id="vin"
            label={t("revalidation.edit.chassi")}
            defaultValue={revalidationToEdit?.vin}
            {...register("vin")}
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormInputText
            id="editOdometer"
            type="number"
            label={t("revalidation.edit.odometer")}
            defaultValue={revalidationToEdit?.odometer}
            inputProps={{ max: 999999 }}
            error={errors.odometer}
            {...register("odometer")}
          />
        </Grid>
      </Grid>

      {HorizontalLine}

      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <span>{t("revalidation.edit.service")}</span>
        </Grid>
        <Grid item md={5} xs={12}>
          <FormInputText
            id="osNumber"
            type="number"
            label={t("revalidation.edit.osNumber")}
            defaultValue={revalidationToEdit?.osNumber}
            inputProps={{ max: 9999999999 }}
            error={errors.osNumber}
            {...register("osNumber")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormInputText
            id="revisionDate"
            type="date"
            label={t("revalidation.edit.revisionDate")}
            defaultValue={revalidationToEdit?.revisionDate}
            InputProps={{
              inputProps: { min: "1900-01-01", max: "2100-01-01" },
            }}
            error={errors.revisionDate}
            {...register("revisionDate")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={3} xs={12} />
        <Grid item md={5} xs={12}>
          <FormInputText
            id="serviceLost"
            label={t("revalidation.edit.lostService")}
            defaultValue={revalidationToEdit?.serviceLost}
            {...register("serviceLost")}
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12} style={{ height: "10px" }}>
          <FormInputText
            select
            id="status"
            label={t("revalidation.edit.status")}
            defaultValue={revalidationToEdit?.status}
            {...register("status")}
          >
            {status.map((status) => (
              <MenuItem key={status} value={status}>
                {formatStatus(status)}
              </MenuItem>
            ))}
          </FormInputText>
        </Grid>
      </Grid>

      {HorizontalLine}

      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <span>{t("revalidation.edit.dealer")}</span>
        </Grid>
        <Grid item md={5} xs={12}>
          <FormInputText
            id="dn"
            type="number"
            label={t("revalidation.edit.dnCode")}
            defaultValue={revalidationToEdit?.dn}
            inputProps={{ max: 999 }}
            error={errors.dn}
            {...register("dn")}
          />
        </Grid>
      </Grid>

      {HorizontalLine}

      <p style={{ fontSize: 12, marginBottom: 30 }}>
        {t("revalidation.edit.requiredFields")}
      </p>

      <ActionButtons
        typeButton="submit"
        saveName={t("button.Save")}
        enableLine={false}
      />
    </MyModal>
  );
}
