import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { Box, Button, Tooltip } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IBoxContent } from "../../interfaces/IBoxContent";

export default function BoxContent({
  children,
  title,
  button,
  titleButton,
  handleClick,
  button2,
  titleButton2,
  handleClick2,
  disabledButton,
  disabledButton2,
  global,
  noGlobal,
  component,
  buttonType,
  onSubmit = () => {},
}: IBoxContent) {
  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Box
      component={component || "div"}
      onSubmit={onSubmit}
      sx={{
        backgroundColor: "var(--white)",
        marginY: "1rem",
        borderRadius: "4px",
        border: "1px solid transparent",
        width: "-webkit-fill-available",
        height: "fit-content",
        boxShadow: "0 0 4px rgb(0 0 0 / 15%)",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid var(--gray-100)",
          display: "flex",
          justifyContent: "space-between",
          placeItems: "center",
          marginRight: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3 style={{ paddingInline: "1rem" }}>{title}</h3>

          {global && (
            <Tooltip title={t("alert.global")}>
              <PublicIcon sx={{ color: "var(--gray-700)" }} />
            </Tooltip>
          )}

          {noGlobal && (
            <Tooltip title={t("alert.noGlobal")}>
              <PublicOffIcon sx={{ color: "var(--gray-700)" }} />
            </Tooltip>
          )}
        </Box>

        {button && isAuthenticated && (
          <Box sx={{ display: "flex", gap: 2 }}>
            {button2 && (
              <Button
                disabled={disabledButton2}
                variant="contained"
                onClick={handleClick2}
              >
                {titleButton2}
              </Button>
            )}
            <Button
              type={buttonType || "button"}
              variant="contained"
              disabled={disabledButton}
              onClick={handleClick}
              sx={{ marginLeft: "25px" }}
            >
              {titleButton}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          padding: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
