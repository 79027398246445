import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useTheme } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactQuill from "react-quill";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";
import ActionButtons from "../../MyModal/ActionButtons";
import "react-quill/dist/quill.snow.css"; // Importa o CSS do tema

import { LabelContainer, LabelForm } from "../Form/label";

type FormValues = {
  question: string;
  systems: number[];
  categoryId: number;
  answer: string;
  active: boolean;
  image: any;
};

interface ISystem {
  id: number;
  name: string;
  active: boolean;
}

interface ICategory {
  id: number;
  name: string;
}

export default function AddNewQuestion({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  yup.setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });
  const [richText, setRichText] = useState("");
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [selectedSystems, setSelectedSystems] = useState<ISystem[]>([]);
  const [systems, setSystems] = useState<ISystem[]>([]);
  const quillRef = useRef<any>(null);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | string>("");
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  useEffect(() => {
    async function load() {
      const [respSystem, respCategories] = await Promise.all([
        axios.get(`${CONFIG.API_URL}faq/systems`, {
          headers: tokenHeaders.headers,
          data: null,
        }),
        axios.get(`${CONFIG.API_URL}faq/categories`, {
          headers: tokenHeaders.headers,
          data: null,
        }),
      ]);

      setSystems(respSystem.data);
      setCategories(respCategories.data.content);
    }
    load();
  }, []);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result?.toString().split(",")[1] || null);
    };

    reader.readAsDataURL(file);
  };

  const addFormData = yup.object().shape({
    question: yup
      .string()
      .max(85)
      .required(t("faq.question.Required question"))
      .label(t("faq.question.inputs.Question")),
    systems: yup
      .array()
      .required(t("faq.question.Required systems"))
      .label(t("faq.question.label_select_systems")),
    categoryId: yup
      .number()
      .required(t("faq.question.Required category"))
      .label(t("faq.question.input.Response")),
    answer: yup
      .string()
      .required(t("faq.question.Required answer"))
      .label(t("faq.question.input.Response")),
    active: yup.boolean().required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(addFormData) });

  useEffect(() => {
    register("systems", {
      validate: (value) =>
        value.length > 0 || t("faq.question.Required systems"),
    });
    register("categoryId", {
      validate: (value) => !!value || t("faq.question.Required category"),
    });
    register("answer", {
      validate: (value) =>
        value.length > 0 || t("faq.question.Required answer"),
    });
  }, [register]);

  const addSystem: SubmitHandler<FormValues> = async (values) => {
    try {
      // Validar o conteúdo do editor
      const editor = quillRef?.current?.getEditor();
      const content = editor.getText().trim();
      if (!content) {
        throw new Error(t("faq.question.Required answer"));
      }

      // Continuar com o envio do formulário
      await axios.post(
        `${CONFIG.API_URL}faq`,
        {
          question: values.question,
          answer: values.answer,
          active: values.active,
          image: imageBase64,
          categoryId: values.categoryId,
          systems: values.systems,
          // Adicione os campos adicionais que deseja enviar
        },
        tokenHeaders,
      );

      watchDataChange(t("faq.question.Question added"));
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.question.question_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
  };

  const handleCategoryChange = (event: any) => {
    const categoryId = event.target.value as number;
    setSelectedCategory(categoryId);
    setValue("categoryId", categoryId);
  };

  const handleSystemsChange = (event: any, value: ISystem[]) => {
    setSelectedSystems(value);
    setValue(
      "systems",
      value.map((system) => system.id),
    );
  };

  const handleChange2 = (
    content: string,
    delta: any,
    source: string,
    editor: any,
  ) => {
    setValue("answer", content);
    setRichText(content);
  };
  const theme = useTheme();
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(addSystem)}
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <LabelContainer>
        <LabelForm required label={t("faq.question.label_select_systems")}>
          <Autocomplete
            multiple
            options={systems}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("faq.question.label_select_systems")}
                // placeholder={t("faq.question.placeholder_systems")}
                error={!!errors.systems}
                helperText={errors.systems?.message}
              />
            )}
            onChange={handleSystemsChange}
            value={selectedSystems}
            ChipProps={{
              style: {
                backgroundColor: theme.palette.primary.main, // Azul
                color: theme.palette.common.white,
              },
              deleteIcon: (
                <CancelIcon style={{ color: theme.palette.common.white }} />
              ), // Ícone de fechar customizado
            }}
          />
        </LabelForm>
        <LabelForm required label={t("faq.question.label_select_category")}>
          <FormControl fullWidth disabled={!selectedSystems.length}>
            <InputLabel
              id="category-select-label"
              style={{ background: "#fff" }}
            >
              {t("faq.question.inputs.Select category")}
            </InputLabel>
            <Select
              labelId="category-select-label"
              value={selectedCategory}
              onChange={handleCategoryChange}
              displayEmpty
              error={!!errors.categoryId}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {errors.categoryId && (
              <p
                style={{
                  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  margin: "3px 14px 0 14px",
                  fontWeight: 400,
                }}
              >
                {errors.categoryId.message}
              </p>
            )}
          </FormControl>
        </LabelForm>

        <LabelForm
          label={t("faq.question.inputs.Question")}
          required
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "85",
            },
          ]}
        >
          <TextField
            placeholder={t("faq.question.inputs.Question")}
            error={!!errors.question}
            style={{ width: "100%" }}
            helperText={errors.question?.message}
            disabled={!selectedSystems.length}
            {...register("question")}
          />
        </LabelForm>

        <LabelForm required label={t("faq.question.inputs.Response")}>
          <div>
            <ReactQuill
              ref={quillRef}
              value={richText}
              readOnly={!selectedSystems.length}
              onChange={handleChange2}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  [{ align: [] }],
                ],
              }}
              formats={[
                "font",
                "header",
                "bold",
                "italic",
                "underline",
                "list",
                "bullet",
                "link",
                "image",
                "align",
              ]}
              style={{
                fontFamily: "sans-serif",
                border: errors.answer ? "1px solid var(--red-100)" : "",
              }} // Define a fonte personalizada
            />
            {errors.answer && (
              <p
                style={{
                  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  margin: "3px 14px 0 14px",
                  fontWeight: 400,
                }}
              >
                {errors.answer.message}
              </p>
            )}
          </div>
        </LabelForm>
        <LabelForm
          label={t("faq.question.inputs.Image")}
          requires={[
            {
              msg: t("faq.requires.recommended_dimension"),
              value: "240 pixels de largura",
            },
            {
              msg: t("faq.requires.image_type"),
              value: "JPEG",
            },
            {
              msg: t("faq.requires.maximum_size"),
              value: "5 MB",
            },
          ]}
        >
          <Grid container spacing={2} columns={{ xs: 6, sm: 8, md: 12 }}>
            <Grid item xs={6}>
              {/* <span>{t("faq.category.UploadImage")}</span> */}

              <input
                disabled={!selectedSystems.length}
                accept=".png, .jpg, .jpeg"
                type="file"
                {...register("image")}
                onChange={convertImageToBase64}
              />
            </Grid>
            <Grid item xs={6} bgcolor="#fff">
              <Box display="flex" alignItems="center">
                <p>Imagem atual:</p>
                <div style={{ width: 50, height: 50, marginLeft: "16px" }}>
                  {imageBase64 && (
                    <img
                      src={`data:image/png;base64,${imageBase64}`}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      alt="main"
                    />
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>

          {errors.image && (
            <Box
              sx={{
                color: "var(--red-100)",
                marginBottom: 3,
              }}
            >
              <span>{String(errors?.image?.message)}</span>
            </Box>
          )}
        </LabelForm>

        <LabelForm
          label={t("faq.system.Status")}
          requires={[
            {
              msg: t("faq.requires.active_inactive"),
            },
          ]}
        >
          <Grid item xs={6}>
            <FormGroup sx={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Switch
                    id="addActive"
                    {...register("active")}
                    defaultChecked
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </LabelForm>
      </LabelContainer>

      <ActionButtons
        saveName={t("button.Add")}
        typeButton="submit"
        disableSave={!selectedSystems.length}
      />
    </Box>
  );
}
