/* eslint no-eval: 0 */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  ptBR,
  esES as es,
  enUS as en,
} from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { IDataGridApiSearch } from "../../../interfaces/IDataGridApiSearch";
import { CONFIG } from "../../../services/config";
// eslint-disable-next-line import/extensions
import { FormInputText } from "../../../components/form/FormInputText";

export default function DataGridApi({
  onRowClick,
  dataUpdated = 0,
  columnsData,
  endPoint,
  rowHeight,
  messageError,
  params1,
  labelParams1,
  typeInput1,
  params2,
  labelParams2,
  typeInput2,
  params3,
  labelParams3,
  typeInput3,
  params4,
  labelParams4,
  typeInput4,
  idRow,
  onCellEditStart,
  onCellEditCommit,
  mock = [],
  host,
  onEmptyMessage,
  CleanButtonStyle,
  SearchButtonStyle,
  CleanButtonType,
  CleanButtonText,
  boxInputstyle,
  gridInputStyle,
  boxButtonMargin,
  label2Params1,
}: IDataGridApiSearch) {
  const { t, i18n } = useTranslation();
  const languages: { [language: string]: any } = {
    ptbr: ptBR,
    en,
    es,
  };

  const [errorFetching, setErrorFetching] = useState(false);
  const {
    tokenHeaders,
    isAuthenticated,
    country: { countryCode },
  } = useContext(AuthContext);
  const [searchParams1, setSearchParams1] = useState("");
  const [searchParams2, setSearchParams2] = useState("");
  const [searchParams3, setSearchParams3] = useState("");
  const [searchParams4, setSearchParams4] = useState("");

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 25,
  });

  const searchWithMock = () => {
    // console.log("mock", mock);
    setErrorFetching(false);
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: [...(mock as never)],
      total: mock?.length || 0,
    }));
  };

  const searchWithAxios = async () => {
    // console.log(
    //   "Aqui: ",
    //   `${host || CONFIG.API_URL}${endPoint}page=${pageState.page}&size=${
    //     pageState.pageSize
    //   }${params1}${searchParams1}${params2}${searchParams2}${params3}${searchParams3}${params4}${searchParams4}`,
    // );
    tokenHeaders.headers.countryCode = countryCode;
    tokenHeaders.headers["Content-Type"] = "application/json";
    axios
      .get(
        `${host || CONFIG.API_URL}${endPoint}page=${pageState.page}&size=${
          pageState.pageSize
        }${params1}${searchParams1}${params2}${searchParams2}${params3}${searchParams3}${params4}${searchParams4}`,
        {
          data: null,
          headers: tokenHeaders.headers,
        },
      )
      .then((response) => {
        // console.log("DataGridApi response", response.data);
        setErrorFetching(false);

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content ? response.data.content : response.data,
          total: response.data.totalElements,
        }));
      })
      .catch((error: any) => {
        setErrorFetching(true);

        if (error.response.data.message) {
          return messageError(error.response.data.message);
        }
      });
  };

  const searchFullData = async () => {
    // console.log("searchFullData", tokenHeaders);
    tokenHeaders.headers["Content-Type"] = "application/json";
    axios
      .get(
        `${host || CONFIG.API_URL}${endPoint}page=${pageState.page}&size=${
          pageState.pageSize
        }`,
        tokenHeaders,
      )
      .then((response) => {
        // console.log("DataGridApi response", response.data);
        setErrorFetching(false);

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content ? response.data.content : response.data,
          total: response.data.totalElements,
        }));
      })
      .catch((error: any) => {
        setErrorFetching(true);

        if (error.response.data.message) {
          return messageError(error.response.data.message);
        }
      });
  };

  const searchData = async () => {
    // console.log("searchParams1", searchParams1);
    setPageState((old) => ({ ...old, isLoading: true }));

    !searchParams1 ? (params1 = "") : true;
    !searchParams2 ? (params2 = "") : true;
    !searchParams3 ? (params3 = "") : true;
    !searchParams4 ? (params4 = "") : true;

    if (mock?.length) {
      searchWithMock();
    } else {
      searchWithAxios();
    }
  };

  const clearInputs = () => {
    const input1 = document.querySelector("#label1") as HTMLInputElement;
    const input2 = document.querySelector("#label2") as HTMLInputElement;
    const input3 = document.querySelector("#label3") as HTMLInputElement;

    input1 ? (input1.value = "") : true;
    input2 ? (input2.value = "") : true;
    input3 ? (input3.value = "") : true;

    setSearchParams1("");
    setSearchParams2("");
    setSearchParams3("");
    setSearchParams4("");

    searchFullData();
  };

  useEffect(() => {
    searchData();
  }, [dataUpdated, pageState.page, pageState.pageSize, countryCode]);

  const columns: GridColDef[] = columnsData;

  if (!isAuthenticated) {
    return (
      <Box>
        <Alert severity="error">{t("userNotSigned.title")}</Alert>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          {t("userNotSigned.warning")}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      {pageState.data ? (
        <Box>
          <Box sx={boxInputstyle ? boxInputstyle : null}>
            <Grid
              container
              spacing={2}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={gridInputStyle ? gridInputStyle : null}
            >
              <Grid item xs={!params2 ? 12 : 4}>
                {params1 && labelParams1 && (
                  <FormInputText
                    id="label1"
                    type={typeInput1 ? typeInput1 : "text"}
                    label={labelParams1}
                    onChange={(event) => setSearchParams1(event.target.value)}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        searchData();
                      }
                    }}
                    inputProps={{
                      maxLength: 17,
                    }}
                  />
                )}
              </Grid>
              {params2 && labelParams2 && (
                <Grid item xs={!params2 ? 12 : 4}>
                  <FormInputText
                    type={typeInput2 ? typeInput2 : "text"}
                    id="label2"
                    label={labelParams2}
                    onChange={(event) => setSearchParams2(event.target.value)}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        searchData();
                      }
                    }}
                    sx={gridInputStyle ? gridInputStyle : null}
                  />
                </Grid>
              )}
              {params3 && labelParams3 && (
                <Grid item xs={!params2 ? 12 : 4}>
                  <FormInputText
                    type={typeInput3 ? typeInput3 : "text"}
                    id="label3"
                    label={labelParams3}
                    onChange={(event) => setSearchParams3(event.target.value)}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        searchData();
                      }
                    }}
                    sx={gridInputStyle ? gridInputStyle : null}
                  />
                </Grid>
              )}
              {params4 && labelParams4 && (
                <Grid item xs={!params2 ? 12 : 4}>
                  <FormInputText
                    type={typeInput4 ? typeInput4 : "text"}
                    id="label4"
                    label={labelParams4}
                    onChange={(event) => setSearchParams4(event.target.value)}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        searchData();
                      }
                    }}
                    sx={gridInputStyle ? gridInputStyle : null}
                  />
                </Grid>
              )}
            </Grid>

            {params1 && labelParams1 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  marginBottom: 2,
                  marginLeft: boxButtonMargin ? boxButtonMargin : null,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    searchData();
                  }}
                  sx={SearchButtonStyle ? SearchButtonStyle : null}
                >
                  {t("button.Search")}
                </Button>
                <Button
                  variant={CleanButtonType ? CleanButtonType : "contained"}
                  onClick={() => {
                    clearInputs();
                  }}
                  sx={CleanButtonStyle ? CleanButtonStyle : null}
                >
                  {CleanButtonText || t("button.Reset")}
                </Button>
              </Box>
            )}
          </Box>
          {label2Params1 && (
            <Box
              sx={{
                marginBottom: "30px",
                fontSize: "12px",
                color: "gray",
              }}
            >
              {label2Params1}
            </Box>
          )}

          {errorFetching && (
            <Box sx={{ marginBottom: 2 }}>
              <span style={{ color: "var(--red-100)" }}>
                {searchParams1 ? (
                  `${t("alert.notResult")}: ${searchParams1}`
                ) : (
                  <>
                    <Alert severity="error">{t("alert.errorFetch")}</Alert>
                    <Alert severity="warning" sx={{ marginY: "1rem" }}>
                      {t("alert.tryAgain")}
                    </Alert>
                  </>
                )}
              </span>
            </Box>
          )}

          {pageState.isLoading && !errorFetching && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              "& .header-style": {
                backgroundColor: "var(--gray-100)",
                minHeight: "4rem",
              },
            }}
          >
            {(!!pageState.data.length || !onEmptyMessage) && (
              <DataGrid
                rowCount={pageState.total}
                localeText={
                  eval(languages[i18n.language]).components.MuiDataGrid
                    .defaultProps.localeText
                }
                getRowId={
                  idRow !== undefined
                    ? (row) => row.pillCategoryId
                    : () => Math.floor(Math.random() * 100000000)
                }
                rowHeight={rowHeight ? rowHeight : 52}
                getRowHeight={() => "auto"}
                rows={pageState.data}
                columns={columns}
                onRowClick={(params: any) => onRowClick(params.row)}
                loading={pageState.isLoading}
                rowsPerPageOptions={[25, 50, 75, 100]}
                // rowsPerPageOptions={[2, 4, 6, 25, 50, 75, 100]}
                pagination
                page={pageState.page}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                  setPageState((old) => ({ ...old, page: newPage }));
                }}
                onPageSizeChange={(newPageSize) =>
                  setPageState((old) => ({
                    ...old,
                    pageSize: newPageSize,
                    page: 0,
                  }))
                }
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                onCellEditStart={onCellEditStart}
                onCellEditCommit={onCellEditCommit}
                autoHeight
                sx={{
                  cursor: "pointer",

                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: 1,
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                }}
              />
            )}
            {!pageState.data.length && <span>{onEmptyMessage}</span>}
          </Box>
        </Box>
      ) : (
        <>
          {errorFetching && (
            <>
              <Alert severity="error">
                {t("alert.errorFetch")}
                {/* There was an error when fetching your request. Either the image list
  is empty or we are having trouble. */}
              </Alert>
              <Alert severity="warning" sx={{ marginY: "1rem" }}>
                {t("alert.tryAgain")}
              </Alert>
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </>
      )}
    </Box>
  );
}
