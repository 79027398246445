import { useTranslation } from "react-i18next";
import { COMPLETED, CANCELLED } from "../constants";

export default function formatStatus(status: String) {
  const { t } = useTranslation();
  if (status === COMPLETED) {
    return t("report.revalidated");
  }
  if (status === CANCELLED) {
    return t("report.cancelled");
  }
}
