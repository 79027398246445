import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useReportRevalidation from "../../hooks/ReportRevalidation";

export default function Report({ callbackRevalidationReport }: any) {
  const { t } = useTranslation();
  const reportRevalidation = useReportRevalidation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeButton = useMemo(() => {
    return (
      dayjs(startDate).isValid() &&
      dayjs(endDate).isValid() &&
      !dayjs(startDate).isAfter(dayjs(endDate)) &&
      status.length
    );
  }, [startDate, endDate, status]);

  const handleSendDownload = () => {
    setButtonLoading(true);
    if (handleChangeButton) {
      const report =
        status === "ALL"
          ? {
              initialDate: dayjs(startDate).format("YYYY-MM-DD"),
              finalDate: dayjs(endDate).format("YYYY-MM-DD"),
            }
          : {
              initialDate: dayjs(startDate).format("YYYY-MM-DD"),
              finalDate: dayjs(endDate).format("YYYY-MM-DD"),
              status,
            };

      reportRevalidation.sendReportGeneration(
        report,
        callbackRevalidationReport,
        setButtonLoading,
      );
    }
  };

  return (
    <Box>
      <Typography fontSize={16} fontWeight={400}>
        {t("report.description")}
      </Typography>
      <Grid
        container
        spacing={2}
        columns={{ xs: 10, sm: 10, md: 122.5 }}
        alignItems="center"
        marginTop={4}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={30.5}>
            <Typography fontSize={16} fontWeight={400}>
              {t("report.period")}
            </Typography>
          </Grid>
          <Grid item xs={45}>
            <DatePicker
              label={t("report.dateStart")}
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newDate) => setStartDate(newDate)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={45}>
            <DatePicker
              label={t("report.dateEnd")}
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newDate) => setEndDate(newDate)}
              renderInput={(params) => <TextField fullWidth {...params} />}
              minDate={startDate}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Grid
        container
        spacing={4}
        borderTop="1px solid var(--gray-100)"
        columns={{ xs: 10, sm: 1, md: 40 }}
        alignItems="center"
        marginTop={4}
      >
        <Grid item xs={10}>
          <Typography fontSize={16} fontWeight={400}>
            {t("report.statusDescription")}
          </Typography>
        </Grid>
        <Grid item xs={15}>
          <FormControl fullWidth>
            <InputLabel>{t("report.status")}</InputLabel>
            <Select
              value={status}
              label={t("report.status")}
              onChange={handleChangeStatus}
            >
              <MenuItem value="ALL">{t("report.all")}</MenuItem>
              <MenuItem value="COMPLETED">{t("report.revalidated")}</MenuItem>
              <MenuItem value="CANCELLED">{t("report.cancelled")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography
        sx={{
          display: "flex",
          placeItems: "center",
          gap: "1rem",
          marginTop: "2rem",
          paddingY: "1.5rem",
          borderTop: "1px solid var(--gray-100)",
          fontSize: 12,
          fontWeight: 400,
        }}
      >
        {t("report.subDescription")}
      </Typography>
      <Button
        variant="contained"
        disabled={!handleChangeButton || buttonLoading}
        onClick={handleSendDownload}
      >
        {buttonLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          t("report.buttonTitle")
        )}
      </Button>
    </Box>
  );
}
