import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { CONFIG } from "../services/config";

interface Report {
  fileName: string;
  fileUrl: string;
  response: string;
  status: "notStarted" | "generating" | "complete";
}

export default function useReportRevalidation() {
  const [report, setReport] = useState<Report>({
    response: "",
    fileName: "",
    fileUrl: "",
    status: "notStarted",
  });
  const [callbackFunction, setCallbackFunction] = useState<Function>(() => {});
  const [callbackButtonLoading, setCallbackButtonLoading] = useState<Function>(
    () => {},
  );
  const { tokenHeaders } = useContext(AuthContext);

  const verifyReportStatus = async (fileName: string) => {
    const header = {
      headers: {
        ...tokenHeaders.headers,
        fileName,
      },
    };
    const result = await axios.get(
      `${CONFIG.SOB_API}revalidate-plan/checkExtraction`,
      header,
    );
    return result.data.status;
  };

  const startReportStatusLoopCheck = async (
    fileName: string,
    fileUrl: string,
    response: string,
  ) => {
    const idFinished = await verifyReportStatus(fileName);

    if (!idFinished) {
      setTimeout(
        () =>
          setReport({
            response,
            status: "generating",
            fileName,
            fileUrl,
          }),
        15000,
      );
    } else {
      setReport({ fileName, fileUrl, response, status: "complete" });
    }
  };

  useEffect(() => {
    const { fileName, fileUrl, response, status } = report;
    if (status !== "notStarted") {
      if (status !== "complete") {
        startReportStatusLoopCheck(fileName, fileUrl, response);
      } else {
        callbackFunction(report);
        callbackButtonLoading(false);
      }
    }
  }, [report]);

  const sendReportGeneration = (
    body: {
      initialDate: string;
      finalDate: string;
      status?: string;
    },
    callbackRevalidationReport: Function,
    setButtonLoading: Function,
  ) => {
    const header = {
      headers: { ...tokenHeaders.headers },
    };
    return axios
      .post(`${CONFIG.SOB_API}revalidate-plan/report`, body, header)
      .then(({ data: { fileName, fileUrl, response } }) => {
        const report: Report = {
          fileName,
          fileUrl,
          response,
          status: "generating",
        };
        setCallbackFunction(() => callbackRevalidationReport);
        setCallbackButtonLoading(() => setButtonLoading);
        setReport(report);
      });
  };

  return { sendReportGeneration, report };
}
